
.mvx-cart-item-quantity {
    flex-direction: row;
    display: flex;
    align-items: center;

    .mdc-text-field {
        height: 35px;
        width: 40px;
    }
}
