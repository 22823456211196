
@import '../../sass/mdc-colors';
@import '~@material/drawer/mdc-drawer';
@import '~@material/list/mdc-list';

.mvx-sidebar {
    position: fixed;

    .mvx-logo {
        height: 150px;
        margin: 1rem 0;
        cursor: pointer;
    }

    .mvx-sidebar__title {
        font-size: 1.50rem;
        text-align: center;
        margin-bottom: -15px;
    }

    .mdc-list-item__graphic {
        margin-right: 26px;
    }
}
