
.mvx-tooltip {
    &__content {
        background: #333333;
        color: white;
        font-weight: bold;
        padding: .25rem .5rem;
        font-size: 1rem;
        border-radius: .5rem;
        max-width: 95vw;
        display: flex;
        align-items: center;

        &__arrow, &__arrow::before {
            position: absolute;
            width: 8px;
            height: 8px;
            z-index: -1;
        }

        &__arrow::before {
            content: '';
            transform: rotate(45deg);
            background: #333333;
        }

        &[data-popper-placement^='top'] > &__arrow {
            bottom: -4px;
        }

        &[data-popper-placement^='bottom'] > &__arrow {
            top: -4px;
        }

        &[data-popper-placement^='left'] > &__arrow {
            right: -4px;
        }

        &[data-popper-placement^='right'] > &__arrow {
            left: -4px;
        }

        &__close, &__close:active, &__close:visited, &__close:hover {
            color: white;
            display: flex;
            align-items: center;
            text-decoration: none;
            margin-left: .5rem;

            i {
                font-size: 1rem;
            }
        }
    }
}
