
.mvx-error {
    background-color: #ffffff;
    font: 14px Roboto, sans-serif;
    display: flex;
    height: 100vh;

    .mvx-error__message {
        margin: auto;
        padding: 1rem;
        text-align: center;

        .mvx-logo {
            height: 180px;
        }

        h1 {
            font-size: 2.2em;
            font-weight: bold;
        }

        p {
            font-size: 1.5em;
            line-height: 1em;
            margin: 1em 0;
        }
    }
}
