
@import '../../sass/layout';
@import '~@material/layout-grid/mdc-layout-grid';

.mvx-search {
    flex: 1;
    margin: 0 .5rem;
    position: relative;
    display: flex;

    &__input {
        height: 32px;
        padding: 4px 48px 4px 8px;
        border: none;
        border-radius: 4px;
        background: $popular;
        flex: 1;
        color: #1a1919;

        &:focus {
            outline-width: 0;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
            0 1px 1px 0 rgba(0, 0, 0, 0.14),
            0 1px 3px 0 rgba(0, 0, 0, 0.12);
        }
    }

    &__close {
        position: absolute;
        top: -3px;
        right: 0;
    }

    &__results {
        background: $popular;
        position: absolute;
        top: 24px;
        width: 200px;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.12);
        list-style: none;
        padding: 0;

        &__result, &__noresult {
            padding: 0 8px;
            height: 36px;
            line-height: 36px;
        }

        &__result {
            cursor: pointer;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:hover {
                background: $mdc-theme-primary;
            }
        }

        &__noresult {
            font-style: italic;
        }
    }

    &--searching {
        .mvx-search__input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
