
@import "~@material/typography/mdc-typography";
@import '../../sass/mdc-layout';
@import "~@material/layout-grid/mdc-layout-grid";
@import '../../sass/mdc-colors';
@import '~@material/theme/variables';

.mvx-checkout-cart-item {
    border-bottom: 1px solid #dddddd;
    padding: .5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.mvx-checkout-car-item--removed {
        opacity: .6;
    }

    &.mvx-checkout-car-item--soldout {
        background: #dddddd;
    }

    &.mvx-checkout-car-item--noprice,
    &.mvx-checkout-car-item--notfound {
        background: $mdc-theme-error;
        color: $mdc-theme-on-error;
    }

    &.mvx-checkout-car-item--soldout .mvx-checkout-cart-item__info__title span.mvx-checkout-cart-item__info__title__title,
    &.mvx-checkout-car-item--soldout .mvx-checkout-cart-item__info__title span.mvx-checkout-cart-item__info__title__reference,
    &.mvx-checkout-car-item--notfound .mvx-checkout-cart-item__info__title span.mvx-checkout-cart-item__info__title__title,
    &.mvx-checkout-car-item--notfound .mvx-checkout-cart-item__info__title span.mvx-checkout-cart-item__info__title__reference {
        text-decoration: line-through;
    }

    .mvx-checkout-cart-item__note {
        line-height: 1rem;

        i {
            font-size: .8rem;
        }
    }

    .mvx-checkout-cart-item__info {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-start;
        align-items: center;

        .mvx-checkout-cart-item__info__title {
            margin: 0 .5rem;

            .mvx-checkout-cart-item__info__title__reference {
                font-size: .9rem;
                font-weight: 100;
                font-style: italic;
            }
        }

        .mvx-checkout-cart-item__info__image {
            background-color: #ffffff;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                display: none;
            }
            height: 60px;
            width: 107px;
            min-width: 107px;
            margin-left: .5rem;
        }
    }

    .mvx-checkout-cart-item__choice {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .mvx-checkout-cart-item__choice--none {
            flex-direction: row;
            align-items: center;
            font-size: .9rem;
            font-style: italic;
            text-align: right;
        }

        .mvx-checkout-cart-item__choice__price {
            margin-right: 1rem;
        }

        .mvx-checkout-cart-item__choice__quantity {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: .8rem;

            .mvx-checkout-cart-item__choice__quantity__packaging {
                max-width: 80px;

                &--invalid-quantity {
                    color: $mdc-theme-error;
                }
            }
        }
    }
}
