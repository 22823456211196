
@import '~@material/typography/mdc-typography';
@import '../../sass/mdc-layout';
@import '~@material/layout-grid/mdc-layout-grid';

.mvx-previous-order {
    border: 1px solid #e4e3e3;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    flex-basis: 320px;
    flex-grow: 1;
    margin: .2rem;
    padding: 1rem;

    &.mvx-previous-order--tmp {
        background: #dddddd;
    }

    .mvx-previous-order__content {
        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
            min-height: auto !important;
        }

        .mvx-previous-order__content__number {
            margin-top: 1rem;
            text-align: center;
        }

        .mvx-previous-order__content__infos {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 1rem 0;

            .mvx-previous-order__content__infos__info {
                margin: 0 .5rem;
            }
        }

        .mvx-previous-order__content__items {
            width: 100%;
            font-size: .9em;

            .mvx-previous-order__content__items__category {
                background: #e9823f;
                padding: .5rem;
                color: #1a1919;
                font-weight: 500;
                text-transform: uppercase;
                font-size: .9rem;
                letter-spacing: 0.0125em;
                margin: 0;

                @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                    font-size: .88rem;
                }
            }

            .mvx-previous-order__content__items__item {
                border-bottom: 1px solid #f5f5f5;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: .2rem .5rem;

                .mvx-previous-order__content__items__item__disabled {
                    color: #aaaaaa;
                }
            }
        }
    }

    .mvx-previous-order__content__actions {
        .mvx-button {
            width: 100%;
        }

        .mvx-previous-order__content__actions__seemore button {
            margin: 1rem 0;
        }
    }
}
