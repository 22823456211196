
@import '../../sass/mdc-colors';
@import '~@material/select/mdc-select';

.mdc-select {
    /*Hack to avoid mdc label overflow*/
    .mdc-floating-label.mdc-floating-label--float-above-hack {
        transform: translateY(-70%) scale(0.75);
    }
}
