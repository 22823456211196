
@import '../sass/mdc-colors';
@import "~@material/theme/variables";
@import "~@material/linear-progress/mdc-linear-progress";

.mvx-loader {
    background-color: #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

    .mvx-loader__message {
        margin: 2rem;
        color: #666666;
        font-size: 1.5rem;
        text-transform: uppercase;
        text-align: center;
    }

    .mvx-loader__progress {
        height: 5px;
        width: 80%;
    }
}
