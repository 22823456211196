
.mvx-categories {
    &__root {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-left: -.5rem;
        margin-right: -.5rem;
    }
}
