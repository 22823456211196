
@import '../sass/_mdc-colors';
@import '~@material/theme/variables';
@import '~@material/snackbar/mdc-snackbar';

.mdc-snackbar {
    z-index: 100;
}

.mvx-message.mvx-message--success .mdc-snackbar__surface {
    background: #018786;
    color: white;
    border-color: white;
}

.mvx-message.mvx-message--error .mdc-snackbar__surface {
    background: $mdc-theme-error;
    color: $mdc-theme-on-error;
    border-color: $mdc-theme-on-error;
}
