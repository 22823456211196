
@import '../sass/layout';
@import '../sass/mdc-layout';
@import '~@material/layout-grid/mdc-layout-grid';
@import '~@material/elevation/mdc-elevation';

.mvx-login {
    display: flex;
    height: 100vh;
    background: #f5f5f5;
    @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
        background: #ffffff;
    }

    .mvx-login__panel {
        background: #ffffff;
        margin: auto;
        min-width: 300px;
        max-width: map-get($mdc-layout-grid-breakpoints, tablet);
        @media (min-width: mdc-layout-grid-breakpoint-min(tablet)) {
            @include mdc-elevation(1);
        }

        .mvx-logo {
            height: 145px;
            margin: 1rem 0;
        }

        .mvx-button {
            margin-top: 1rem;
            width: 100%;
        }
    }

    /* IE11 */
    @media all and (-ms-high-contrast: none) {
        .mvx-login__panel {
            height: 480px;
        }
    }
}
