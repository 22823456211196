
@import '../sass/mdc-colors';
@import '~@material/dialog/mdc-dialog';

.mdc-dialog {
    z-index: 1000;
}

.mvx-modal {
    .mvx-modal__close {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        background: transparent;
        border: none;
        padding: 2rem;

        &:focus {
            outline: 0;
        }
    }
}
