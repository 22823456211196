
@import '../../sass/layout';
@import '~@material/layout-grid/mdc-layout-grid';

.mvx-catalog--searching {
    @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
        .mdc-top-app-bar__title, .mvx-brand, .mvx-burger {
            display: none;
        }
    }
}

.navbar__cart {
    cursor: pointer;

    a {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.mdc-top-app-bar__action-item.navbar__favorite {
    color: $popular;
    border-color: $popular;
}

.mvx-about-modal {
    p {
        font-size: .9rem;
    }

    .mvx-logo {
        height: 100px;
        width: 120px;
        margin: 0 auto;
    }
}
