
.mvx-frame .mvx-frame__content {
    min-width: 300px;
}

/* content and all other static elements should be reduced */
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mvx-frame__content,
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mvx-frame__content .mdc-top-app-bar,
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mvx-frame__content .mvx-checkout__step__nav,
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mvx-frame__content .mvx-tab-bar--fixed {
    width: calc(100% - 256px);
}
