
.mvx-scroll-top {
    border: 2px solid;
    border-radius: 30px;
    position: fixed;
    bottom: 1rem;
    z-index: 10;
    right: .5rem;
    padding: 11px;
    background: white;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, .35);
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
