
.mvx-badge {
    position: relative;

    .mvx-badge__value {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 50%;
        background: black;
        color: white;
        font-size: .70em;
        text-align: center;
        z-index: -1;
    }
}
