
@import '../../sass/mdc-colors';
@import '../../sass/mdc-layout';
@import '~@material/theme/variables';
@import '~@material/layout-grid/mdc-layout-grid';

.mvx-catalog-article {
    border: 1px solid #e4e3e3;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    flex-direction: column;
    flex-basis: 240px;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    margin: .2rem;
    max-width: 340px;

    @media (min-width: mdc-layout-grid-breakpoint-min(largedesktop)) {
        max-width: 300px;
    }

    &.mvx-catalog-article--sold-out {
        background: #cccccc;
        /*opacity: .6;*/
    }

    &.mvx-catalog-article--popular {
        background: $popular;
    }

    .mvx-catalog-article__media {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        position: relative;
        box-sizing: border-box;
        font-weight: 100;
        color: #666666;

        &:before {
            display: block;
            content: "";
            margin-top: 56.25%;
        }

        &.mvx-catalog-article__media--zoomable {
            @media (min-width: mdc-layout-grid-breakpoint-min(tablet)) {
                cursor: pointer;
            }
        }
    }

    .mvx-catalog-article__headers {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .mvx-catalog-article__headers__type {
            height: 36px;
            line-height: 36px;
            padding: 0 .5rem;
            color: white;
            background: rgba(233, 130, 63, .90);
            z-index: 1;
        }

        .mvx-catalog-article__headers__price {
            background: rgba(233, 130, 63, .70);

            &--set {
                background: rgba(233, 130, 63, .90);
            }

            .mdc-button__label {
                color: white;
                min-width: 85px;

                .mvx-loader {
                    background: transparent;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .mvx-catalog-article__back {
        margin-top: -24px;
        z-index: 1;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        padding: 0 .5rem;
        font-size: .9rem;
        background: #dbdbdb;
    }

    .mvx-catalog-article__stock {
        height: 20px;
        position: absolute;
        transform: translate(-10%, 0) rotate(29.36deg);
        width: 125%;
        top: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #cccccc;
        text-transform: uppercase;
        font-size: .9rem;
    }

    .mvx-catalog-article__stock-infos {
        background: #dbdbdb;
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        right: 1.5rem;
        padding: 0 .75rem;
        border: 1px solid #e4e3e3;
        z-index: 100;
        font-size: .85rem;

        .mvx-catalog-article__stock-infos__alternates {
            font-size: .9rem;
            margin-bottom: .25rem;

            a {
                color: $mdc-theme-primary;
                font-weight: bold;
                cursor: pointer;
            }

            ul {
                margin: 0;
                padding-left: 1rem;
                list-style-type: square;
            }
        }
    }

    .mvx-catalog-article__body {
        padding: 0 .75rem;
        display: flex;
        flex-direction: column;
        flex: 1;

        .mvx-catalog-article__body__title {
            margin: .25rem 0;
            font-size: .875rem;
            line-height: 1.375rem;
            font-weight: 500;
            letter-spacing: .00714em;
            flex: 1;

            .mvx-catalog-article__body__title__reference {
                font-size: .9rem;
                font-weight: 100;
                font-style: italic;
            }
        }

        .mvx-catalog-article__body__action {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .mvx-catalog-article__body__action__packaging {
                font-size: .8rem;

                &--invalid-quantity {
                    color: $mdc-theme-error;
                }
            }
        }
    }
}
