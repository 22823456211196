
@import '../../sass/_mdc-colors';
@import '~@material/textfield/mdc-text-field';
@import '~@material/textfield/helper-text/mdc-text-field-helper-text';

.mdc-text-field {
    /*Hack to avoid mdc label overflow*/
    .mdc-floating-label.mdc-floating-label--float-above-hack {
        transform: translateY(-70%) scale(0.75);
        top: 20px;
    }
}
