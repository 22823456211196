
@import '../sass/mdc-colors';
@import '../sass/mdc-layout';
@import '~@material/layout-grid/mdc-layout-grid';

.mvx-tab-bar.mvx-tab-bar--fixed {
    top: 64px;
    @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
        top: 55px;
    }
}

.mvx-checkout {
    .mvx-checkout__disclaimer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        text-align: center;
        margin: 1rem 0;
    }

    .mvx-checkout__legal-prices {
        text-align: right;
        margin-top: 0;
    }

    .mvx-checkout__success {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .mvx-checkout__success__inner {
            font-size: 1.4rem;
            padding: 1rem;
            margin: 1rem;
            background: #f7f7f7;
            border: 1px solid #cccccc;
        }
    }

    .mvx-checkout__cart-category {
        background: $mdc-theme-primary;
        border-bottom: 1px solid #dddddd;
        position: sticky;
        top: 120px;
        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
            top: 110px;
        }
        z-index: 1;
        padding: .5rem;
        color: $mdc-theme-on-primary;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.1rem;
        letter-spacing: 0.0125em;
        margin: 0;

        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
            font-size: .88rem;
        }
    }

    .mvx-checkout__step .mvx-select {
        width: 100%;
    }

    .mvx-transport {
        font-style: italic;
    }
}
