
@import '../../sass/mdc-colors';

.mvx-menu-link {
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &.mvx-menu-link--selected {
        background: $mdc-theme-primary !important;
        border: 1px solid $mdc-theme-primary !important;
    }

    &.mvx-menu-link--outlined {
        background: #e4e3e3;
        border: 1px solid #f5f5f5;
    }

    a {
        font-weight: 500;
        padding: 1.5rem;
        font-size: 1.5rem;
        text-decoration: none;
        text-align: center;
        color: #333333;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }

    i {
        font-size: 2rem;
        margin-bottom: .5rem;
    }
}
