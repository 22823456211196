
.mvx-orders {
    .mvx-orders__disclaimer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .mvx-orders__cards {
        display: flex;
        flex-wrap: wrap;
        margin-left: -.2rem;
        margin-right: -.2rem;
    }
}
