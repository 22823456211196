
@import '../../sass/mdc-colors';
@import '~@material/theme/variables';
@import '../../sass/mdc-layout';
@import '~@material/layout-grid/mdc-layout-grid';
@import '~@material/button/mdc-button';

.mvx-checkout {
    .mvx-checkout__step {
        .mvx-checkout__step__inner {
            padding: 56px 0;
            max-width: 1000px;
            margin: 0 auto;
        }

        .mvx-checkout__step__nav {
            position: fixed;
            z-index: 10;
            bottom: 0;
            background: #f5f5f5;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            box-sizing: border-box;
            width: 100%;

            .mvx-checkout__step__nav__previous {
                display: flex;
                align-items: center;

                .mvx-button {
                    min-width: 32px;
                    @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                        padding: 0;
                    }
                }

                .mvx-checkout__step__nav__previous__label {
                    display: none;
                    @media (min-width: mdc-layout-grid-breakpoint-min(desktop)) {
                        display: inline;
                    }
                }
            }

            .mvx-checkout__step__nav__next {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .mvx-checkout__step__nav__next__total {
                    margin-right: 1rem;
                    font-size: 1.2rem;
                    font-weight: 500;

                    .mvx-checkout__step__nav__next__total__label {
                        text-transform: uppercase;
                        color: rgba(0, 0, 0, 0.6);
                        display: none;
                        @media (min-width: mdc-layout-grid-breakpoint-min(tablet)) {
                            display: inline;
                        }
                    }

                    .mvx-checkout__step__nav__next__total__amount {
                        color: $mdc-theme-primary;
                        font-size: 1.3rem;
                        font-weight: 800;
                    }
                }

                .mvx-button {
                    @include mdc-button-shape-radius(0);

                    .mvx-checkout__step__nav__next__button-short {
                        display: none;
                        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                            display: inline;
                        }
                    }

                    .mvx-checkout__step__nav__next__button {
                        display: inline;
                        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
