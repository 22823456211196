
@import '../sass/mdc-colors';
@import '../sass/mdc-layout';
@import '~@material/layout-grid/mdc-layout-grid';
@import '~@material/list/mdc-list';
@import '~@material/menu-surface/mdc-menu-surface';
@import '~@material/menu/mdc-menu';

.mvx-articles {
    .mvx-articles__section {
        color: rgba(0, 0, 0, 0.6);
        padding: 0 1rem;
        height: 56px;
        background: #f5f5f5;
        position: sticky;
        top: 64px;
        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
            top: 55px;

            .mvx-button {
                .mdc-button__label {
                    display: none;
                }
            }
        }
        z-index: 3;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1rem;
        letter-spacing: 0.0125em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -2rem;
        margin-bottom: 0;

        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
            &__nav {
                button {
                    margin: 0;
                    padding: 0;
                    min-width: 36px;
                    width: 36px;

                    .mdc-button__icon.mvx-button__trailing-icon {
                        margin: 0;
                    }
                }
            }
        }

        &__title {
            flex: 1;
            margin: 0 1rem;

            @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                font-size: .88rem;
                small, .mvx-articles__section__title__level1 {
                    display: none;
                }
            }
        }

        &__filters {
            right: 1rem;
            top: 120px !important;
            @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                top: 110px !important;
            }
            left: auto !important;
            overflow: auto;
            height: 100%;
            padding-bottom: 120px;

            .mdc-list-item__text {
                font-size: .9rem;
            }

            &__sub {
                height: 24px;
                margin: .5rem;

                .mdc-list-item__text {
                    font-size: .8rem;
                }
            }

            &__icon {
                font-size: 16px;
            }
        }
    }

    .mvx-articles__category {
        margin-bottom: 1rem;

        .mvx-articles__category__title {
            background: $mdc-theme-primary;
            position: sticky;
            top: 120px;
            @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
                top: 110px;

                small {
                    display: none;
                }
            }
            z-index: 2;
            padding: .7rem;
            color: $mdc-theme-on-primary;
            font-weight: 500;
            text-transform: uppercase;
            font-size: .9rem;
            letter-spacing: 0.0125em;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .mvx-articles__category__cards {
            display: flex;
            flex-wrap: wrap;
            margin-left: -.2rem;
            margin-right: -.2rem;
        }
    }
}
