
@import '../../sass/mdc-colors';

$mdc-switch-baseline-theme-color: $mdc-theme-primary;
@import '~@material/switch/mdc-switch';

.mvx-switch {
    .mdc-switch {
        top: 2px;
    }

    .mvx-switch__label {
        margin-left: .5rem;
    }
}
