
@import '../sass/mdc-colors';
@import '~@material/theme/variables';

.mvx-text-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    border: 1px solid;
    position: fixed;
    margin: 0;
    z-index: 100;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    height: 56px;

    &.mvx-text-message--error {
        background: $mdc-theme-error;
        color: $mdc-theme-on-error;
        border-color: $mdc-theme-on-error;
    }

    .mvx-text-message__content {
        margin: 0;
        @import '../sass/mdc-layout';
        @import '~@material/layout-grid/mdc-layout-grid';
        @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
            font-size: .85rem;
        }
        text-align: justify;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        i {
            font-size: 18px;
            margin-right: 1rem;
        }
    }
}
