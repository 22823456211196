
@import '../../sass/mdc-colors';
@import '~@material/tab-bar/mdc-tab-bar';
@import '~@material/tab-scroller/mdc-tab-scroller';
@import '~@material/tab-indicator/mdc-tab-indicator';
@import '~@material/tab/mdc-tab';

.mvx-tab-bar.mvx-tab-bar--fixed {
    position: fixed;
    top: 56px;
    background: #f7f7f7;
    z-index: 10;

    .mdc-tab {
        height: 56px;
    }
}

.mvx-tab.mvx-tab--disabled {
    opacity: .3;
    pointer-events: none;
}

@import '../../sass/mdc-layout';
@import '~@material/layout-grid/mdc-layout-grid';
.mvx-tab .mdc-tab__text-label {
    @media (max-width: mdc-layout-grid-breakpoint-max(phone)) {
        display: none;
    }
}
