
@import '../sass/mdc-colors';
@import '~@material/button/mdc-button';

.mvx-button {
    &--active:before {
        opacity: 0.08 !important;
        background-color: var(--mdc-theme-primary, #e9823f);
    }

    &.mvx-button--disabled {
        cursor: not-allowed;
    }

    .mdc-button__icon.mvx-button__trailing-icon {
        margin-left: 8px;
    }
}
