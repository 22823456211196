
@import '../../sass/mdc-colors';
@import '~@material/top-app-bar/mdc-top-app-bar';

.mvx-app-bar {
    .mvx-burger {
        padding-top: 2px;
        margin-right: .5rem;
    }

    .mdc-top-app-bar__title {
        padding-left: 10px;
    }

    @import '../../sass/mdc-layout';
    @import '~@material/layout-grid/mdc-layout-grid';

    .mvx-brand {
        padding: 12px 0;
        width: 54px;
        @media (min-width: mdc-layout-grid-breakpoint-max(tablet)) {
            display: none;
        }
    }

    .mvx-logo {
        background-image: url('../../static/logo-mini.svg');
        height: 26px;
        width: 50px;
        border: 2px solid #1a1919;
        background-size: 80%;
    }

    .mvx-app-bar__subtitle {
        font-size: 1.1rem;
    }
}
